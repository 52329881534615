import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} dark={true} title={siteTitle}>
      <div className="w-full lg:w-7/12 mx-auto px-4 lg:px-2 mb-32">
        <h1 className="mb-12 text-4xl lg:text-6xl font-bold text-center">
          404: Not Found
        </h1>

        <p>oops! You just hit a route that doesn&#39;t exist....</p>
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
